import Axios from "axios";
const base_Url = "https://oms-meghalaya.thesaicomputers.com/api";
// const base_Url = "http://localhost:8089";
// const base_Url = "https://stage-oms.thesaicomputers.com/oms";

export function sigUp(values) {
  let body = {
    USER_NAME: values.fullName,
    FATHER_NAME: values.fatherName,
    MOBILE_NO: values.mobileNo,
    EMAIL_ID: values.email,
    PASS: values.password,
    DESG: values.designation,
  };
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/user/register`, body)
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function login(values) {
  let body = {
    user_id: values.userId,
    password: values.password,
  };
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/user/login`, body)
      .then((response) => {
        res(response.data);
        console.log("response on loginnn", response);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}
